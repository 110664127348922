import React from 'react';
import {
  ColourInspector,
  LayoutProvider,
  LogoInspector,
  SliceHeader
} from '@lam-agency/toolkit/components';
import { parseButtonDataFromFigma } from '@lam-agency/toolkit/utils/figma';
import { ISliceHeader } from '@lam-agency/toolkit/components/Common/SliceHeader';

import * as styles from './styles.module.scss';

interface IProps {
  data: {
    fields: {
      header: ISliceHeader;
      'heading/1': string;
      'heading/2': string;
      'buttonText/1': string;
      'buttonText/2': string;
    };
  };
}

const FigmaStyleInspector = ({ data: { fields } }: IProps) => {
  const heading1 = fields['heading/1'];
  const heading2 = fields['heading/2'];
  const buttonText1 = fields['buttonText/1'];
  const buttonText2 = fields['buttonText/2'];

  //

  const buttonsLeft = parseButtonDataFromFigma([buttonText1]);
  const buttonsRight = parseButtonDataFromFigma([buttonText2]);

  //

  return (
    <LayoutProvider grid paddingX paddingY>
      <div className={styles.logos}>
        <SliceHeader
          className={styles.sliceHeader}
          data={{
            heading: heading1,
            buttons: buttonsLeft,
            alignButtons: 'left'
          }}
        />

        <LogoInspector />
      </div>

      <div className={styles.colours}>
        <SliceHeader
          className={styles.sliceHeader}
          data={{
            heading: heading2,
            buttons: buttonsRight,
            alignButtons: 'left'
          }}
        />
        <ColourInspector />
      </div>
    </LayoutProvider>
  );
};

export default FigmaStyleInspector;
