import React from 'react';
import cn from 'classnames';

import * as styles from './styles.module.scss';
import { SVG } from '@lam-agency/toolkit/components';
import { IntersectionAnimation } from '@lam-agency/toolkit/components';

const LogoInspector = () => {
  const variants = [
    {
      theme: 'light',
      buttons: [
        {
          text: 'Copy PNG',
          icon: 'copy'
        },
        {
          text: 'Copy SVG',
          icon: 'copy'
        },
        {
          text: 'Copy Code',
          icon: 'copy'
        }
      ]
    },
    {
      theme: 'dark',
      buttons: [
        {
          text: 'Copy PNG',
          icon: 'copy'
        },
        {
          text: 'Copy SVG',
          icon: 'copy'
        },
        {
          text: 'Copy Code',
          icon: 'copy'
        }
      ]
    }
  ];

  return (
    <div className={styles.container}>
      {variants.map((variant, index) => {
        const { theme, buttons } = variant || {};
        const delay = index * 50;
        return (
          <IntersectionAnimation
            key={`logo-inspector-logo-${theme}-${index}`}
            className={cn(styles.cardWrapper, styles[theme])}
            animation="fadeGrow"
            delay={delay}
          >
            <div className={cn(styles.card)}>
              <div className={styles.buttons}>
                {/* {buttons.map((button) => {
                const { text, icon } = button || {};

                return (
                  <Button
                    iconRight={icon}
                    theme={theme === 'dark' ? 'light' : 'dark'}
                  >
                    {text}
                  </Button>
                );
              })} */}
              </div>
              <figure className={styles.svg}>
                <SVG svg="logo" />
              </figure>
            </div>
          </IntersectionAnimation>
        );
      })}
    </div>
  );
};

export default LogoInspector;
